// import { GatsbyBrowser } from "gatsby";

import React from "react";

// import { HelmetProvider } from "react-helmet-async";


export const wrapRootElement = ({
  element,
}) => {
  return <React.Fragment>{element}</React.Fragment>;
};
