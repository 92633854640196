const DOMAIN: string = "@@games/sliding-puzzle/";
export const INITIALIZE: string = `${DOMAIN}initialize`;
export const DISPOSE: string = `${DOMAIN}dispose`;

const GamesSlidingPuzzleActionType = {
  INITIALIZE,
  DISPOSE,
};

export default GamesSlidingPuzzleActionType;
