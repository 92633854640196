import {
  AnyAction,
  Store,
  StoreEnhancerStoreCreator,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";

import States from "./states";
import { createLogger } from "redux-logger";
import gamesSlidingPuzzleReducer from "./GamesSlidingPuzzle/reducer";

// import sagas from "./sagas";

export default function configureStore(
  initialState?: States | undefined
): Store<States, AnyAction> {
  const middlewares = [];

  // // redux-saga
  // const saga = createSagaMiddleware();
  // middlewares.push(saga);

  // redux-logger
  if (process.env.NODE_ENV !== "production") {
    const logLevel = "warn";
    const reduxLogger = createLogger({
      collapsed: true,
      level: logLevel,
    });
    middlewares.push(reduxLogger);
  }

  const rootReducer = combineReducers<States>({
    gamesSlidingPuzzle: gamesSlidingPuzzleReducer,
  });

  const enhancers = [];
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }
  const composedEnhancers = compose<StoreEnhancerStoreCreator>(
    applyMiddleware(...middlewares),
    ...enhancers
  );

  // redux-store
  const store = createStore(rootReducer, initialState, composedEnhancers);

  // saga.run(sagas);

  return store;
}
