import Action from "../Action";
import ActionType from "./ActionType";
import Payload from "./Payload";
import State from "./State";

const initialState: State = {
  sceneKey: "",
  game: undefined,
};

export default function gamesSlidingPuzzleReducer(
  state: State = initialState,
  action: Action<Payload>
): State {
  switch (action.type) {
    case ActionType.INITIALIZE:
      return {
        ...state,
        sceneKey: action.payload.sceneKey,
        game: action.payload.game,
      };
    default:
      return state;
  }
}
